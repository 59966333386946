.bg {
    background: #fff;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #fff, #fff);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #fff, #fff);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-image: linear-gradient(to right, #fff, #fff);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    display: block;
  }
  
  .content {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
  }
  
  .my-card {
    background-color: rgba(255, 255, 255, 0.8);
    /*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);*/
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
  }
  