body {
  overflow-y: scroll;
  background: #ffffff !important;
}

.bg-warnings {
  background-color: #c1272d !important;
  color: white !important;
}

.alert-info {
  color: #0c5460;
  background-color: LIGHTGRAY !important;
  border-color: #bee5eb;
}

.btn-info {
  color: #fff;
  background-color: #c1272d !important;
  border-color: #c1272d !important;
}

#about-btn {
  color: white;
  background: #c1272d !important;
  border: #c1272d !important;
}

.aaa {
  color: #c1272d;
  text-decoration: none;
  background-color: transparent;
}

.btn-light {
  color: #ffffff !important;
  background-color: #c1272d !important;
  border-color: #c1272d !important;
}

.text-primarys {
  color: #c1272d !important;
  font-size: larger;
  font-weight: bolder;
}

.text-infos {
  color: #c1272d !important;
  font-weight: bolder;
}

.mmmm {
  background-color: gray !important;
}

.nnnn {
  background-color: #fff !important;
}

.bg-black {
  background-color: white !important;
  color: black !important;
  padding: 10px !important;
}
